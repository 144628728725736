import { Duration } from "luxon";

/**
 * Formats a duration object into a digital clock format
 * @example
 *   formatClockDuration({ seconds: 100 }) // "01:40"
 *   formatClockDuration({ seconds: 10000 }) // "2:46:40"
 **/
export const formatClockDuration = (durationLike: { seconds: number }) => {
  const duration = Duration.fromDurationLike(durationLike).rescale();

  // If more then 0 hours (an hour or above), so the hour
  if (duration.hours > 0) {
    // Ex. "1:05:39"
    return duration.toFormat("h:mm:ss");
  } else {
    // Ex. "36:07"
    return duration.toFormat("mm:ss");
  }
};
