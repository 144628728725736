/**
 * Format a number to a string with a "K" suffix
 * @example
 *  formatThousands(100) // "100"
 *  formatThousands(1000) // "1K"
 *  formatThousands(33333) // "33K"
 */
export const formatThousands = (val: number, fixedDecimal?: boolean) => {
  if (val > 1000) {
    if (fixedDecimal) {
      return `${(val / 1000).toFixed(1)}k`;
    }

    return `${Math.round(val / 1000)}k`;
  }
  return val.toString();
};
